import '../css/svg.css';

const NextJsIcon = () => {
    return (
        <svg class="iconSize" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175.42 166.81">
            <rect class="cls-2222" x="2.09" y="2.12" width="171.41" height="162.8" rx="37.01" ry="37.01"/>
            <path class="cls-1111" d="m137.28,2c19.95,0,36.13,16.17,36.13,36.13v90.55c0,19.95-16.17,36.13-36.13,36.13H38.13c-19.95,0-36.13-16.17-36.13-36.13V38.13C2,18.17,18.17,2,38.13,2h99.16m0-2H38.13C17.1,0,0,17.1,0,38.13v90.55c0,21.02,17.1,38.13,38.13,38.13h99.16c21.02,0,38.13-17.1,38.13-38.13V38.13c0-21.02-17.1-38.13-38.13-38.13h0Z"/>
            <path class="cls-1111" d="m83.48,18.89c-.28.03-1.16.11-1.96.18-18.33,1.65-35.51,11.55-46.38,26.75-6.06,8.45-9.93,18.04-11.39,28.2-.52,3.55-.58,4.59-.58,9.4s.06,5.85.58,9.4c3.51,24.24,20.76,44.61,44.15,52.15,4.19,1.35,8.61,2.27,13.63,2.83,1.96.21,10.41.21,12.37,0,8.67-.96,16.01-3.1,23.26-6.8,1.11-.57,1.32-.72,1.17-.85-.1-.08-4.83-6.42-10.51-14.09l-10.32-13.94-12.93-19.14c-7.12-10.52-12.97-19.13-13.02-19.13-.05-.01-.1,8.49-.13,18.88-.04,18.18-.05,18.91-.28,19.34-.33.62-.58.87-1.11,1.15-.4.2-.76.24-2.66.24h-2.18l-.58-.37c-.38-.24-.66-.56-.85-.92l-.26-.57.03-25.3.04-25.31.39-.49c.2-.26.63-.61.93-.77.52-.25.72-.28,2.9-.28,2.57,0,3,.1,3.67.83.19.2,7.19,10.75,15.57,23.46,8.38,12.71,19.84,30.06,25.46,38.57l10.22,15.48.52-.34c4.58-2.98,9.43-7.22,13.26-11.63,8.16-9.38,13.43-20.81,15.19-33,.52-3.55.58-4.59.58-9.4s-.06-5.85-.58-9.4c-3.51-24.24-20.76-44.61-44.15-52.15-4.13-1.34-8.52-2.26-13.44-2.81-1.21-.13-9.55-.26-10.6-.16h0Zm26.42,39.04c.61.3,1.1.88,1.27,1.49.1.33.13,7.34.1,23.15l-.04,22.69-4-6.13-4.01-6.13v-16.49c0-10.66.05-16.66.13-16.95.2-.71.64-1.26,1.25-1.59.52-.26.71-.29,2.69-.29,1.87,0,2.2.03,2.61.25h0Z"/>
        </svg>
    );
}

export default NextJsIcon;