const Blender = () => {
    return (
        <svg className=" iconSize" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175.42 166.81">
            <rect class="clsb-3" x="2.09" y="2.12" width="171.41" height="162.8" rx="37.01" ry="37.01"/>
            <path class="clsb-1" d="m137.28,2c19.95,0,36.13,16.17,36.13,36.13v90.55c0,19.95-16.17,36.13-36.13,36.13H38.13c-19.95,0-36.13-16.17-36.13-36.13V38.13C2,18.17,18.17,2,38.13,2h99.16m0-2H38.13C17.1,0,0,17.1,0,38.13v90.55c0,21.02,17.1,38.13,38.13,38.13h99.16c21.02,0,38.13-17.1,38.13-38.13V38.13c0-21.02-17.1-38.13-38.13-38.13h0Z"/>
            <g id="SVGRepo_iconCarrier" data-name="SVGRepo iconCarrier">
              <g id="_2069a460dcf28295e231f3111e0426ac" data-name="2069a460dcf28295e231f3111e0426ac">
                <path class="clsb-2" d="m152.04,88.82c-.77-5.43-2.65-10.58-5.59-15.3-2.56-4.12-5.85-7.79-9.78-10.91h.01s-41.23-31.68-41.23-31.68c-.03-.03-.07-.06-.09-.07-1.37-1.05-3.23-1.63-5.22-1.63s-3.93.58-5.43,1.63c-1.61,1.13-2.53,2.62-2.6,4.21-.06,1.48.65,2.91,2,4.02,2.65,2.09,5.3,4.23,7.95,6.37,2.57,2.08,5.22,4.22,7.86,6.31l-50.5-.03c-5.78,0-9.71,2.71-10.52,7.25-.36,1.99.27,4.18,1.67,5.85,1.49,1.78,3.65,2.75,6.08,2.75h3.85c5.32,0,10.66-.01,16-.02h5.09s-45.83,33.9-45.83,33.9l-.18.13c-2.56,1.96-4.24,4.71-4.6,7.55-.27,2.15.23,4.11,1.45,5.68,1.46,1.87,3.73,2.9,6.4,2.9s5.31-1,7.55-2.81l24.75-20.26c-.11.99-.23,2.44-.22,3.49.03,1.67.56,5.01,1.41,7.73,1.75,5.58,4.7,10.69,8.77,15.18,4.23,4.67,9.38,8.37,15.31,11,6.27,2.77,13.01,4.18,20.03,4.18h.07c7.05-.02,13.81-1.45,20.1-4.25,5.94-2.66,11.09-6.38,15.31-11.04,4.06-4.51,7.01-9.62,8.75-15.2.86-2.76,1.42-5.62,1.65-8.49.22-2.79.12-5.62-.27-8.43Zm-45.09,26.76c-15.48,0-28.03-11.23-28.03-25.08s12.55-25.08,28.03-25.08,28.03,11.23,28.03,25.08c0,13.85-12.55,25.08-28.03,25.08Zm16.16-27.28c.23,4.13-1.42,7.97-4.32,10.81-2.95,2.89-7.16,4.71-11.87,4.71s-8.91-1.82-11.87-4.71c-2.9-2.84-4.55-6.68-4.32-10.81.22-4.02,2.18-7.56,5.13-10.07,2.9-2.47,6.8-3.97,11.05-3.97s8.15,1.51,11.05,3.97c2.95,2.51,4.91,6.05,5.14,10.07Z"/>
            </g>
            </g>
        </svg>
    );
}

export default Blender;